<template>
  <router-link
    tag="a"
    :title="$t('buttons.edit')"
    class="btn btn-success"
    :to="{ name: routeName, params: { id: recordId } }"
    data-test="btn_edit"
  >
    <i class="fa fa-edit"></i> {{ $t('buttons.edit') }}
  </router-link>
</template>

<script>
export default {
  name: 'ButtonEdit',
  props: {
    routeName: {
      type: String,
      default: ''
    },
    recordId: {
      type: Number,
      required: true
    }
  }
}
</script>
