<template>
  <table class="table table-striped table-bordered">
    <tbody>
    <tr>
      <th>{{ $t('system.created_at') }}</th>
      <td>{{ record.createdAt | prettyDateTime }}</td>
    </tr>
    <tr>
      <th>{{ $t('system.modified_at') }}</th>
      <td>{{ record.modifiedAt | prettyDateTime }}</td>
    </tr>
    <tr>
      <th>{{ $t('system.created_by') }}</th>
      <td>{{ userNameById(record.createdBy) }} ({{ record.createdBy }})</td>
    </tr>
    <tr v-if="record.modifiedBy">
      <th>{{ $t('system.modified_by') }}</th>
      <td>{{ userNameById(record.modifiedBy) }} ({{ record.modifiedBy }})</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import UserService from '../../services/user/UserService'

export default {
  name: 'DetailSystemRows',
  props: {
    record: {
      type: Object
    }
  },
  methods: {
    userNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    }
  }
}
</script>

<style lang="scss" scoped>
.table-bordered th:first-child {
  width: 200px;
}
</style>
