<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <template v-if="dataLoaded">
                <app-button-edit
                  v-if="isEditButtonVisible"
                  route-name="car_edit"
                  :record-id="car.id"
                >
                </app-button-edit>
                <app-button-delete-with-usages
                  @deleteRecord="deleteCar"
                  @load-data="checkUsages"
                  @page-change="setUsagesPageAndGetRecords"
                  :data="usages.data"
                  :total-count="usages.totalCount"
                  :page="usages.page"
                  :showLoader="usages.showLoader"
                  :config="usagesDataTableConfig"
                  :showDeleteButton="isDeleteButtonVisible"
                ></app-button-delete-with-usages>
              </template>
              <app-button-close route-name="car_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div v-if="dataLoaded" class="card">
        <div class="card-body">
          <table class="table table-bordered table-hover">
            <tbody>
            <tr>
              <th>{{ $t('car.id') }}</th>
              <td>{{ car.id }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.title') }}</th>
              <td>{{ car.title }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.brand') }}</th>
              <td>{{ car.brand }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.model') }}</th>
              <td>{{ car.model }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.productionYear') }}</th>
              <td>{{ car.productionYear }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.type') }}</th>
              <td>{{ getCarTypeByValue(car.type) }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.description') }}</th>
              <td>{{ car.description }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.image') }}</th>
              <td>
                <app-media
                  v-if="carImage"
                  :media="carImage"
                  :width="480"
                  :height="270"
                  show-media-info
                >
                </app-media>
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-center">{{ $t('car.engine.header') }}</th>
            </tr>
            <tr>
              <th>{{ $t('car.engine.fuel') }}</th>
              <td>{{ car.engine.fuel }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.engine.drive') }}</th>
              <td>{{ car.engine.drive }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.engine.gearbox') }}</th>
              <td>{{ car.engine.gearbox }}</td>
            </tr>
            <tr>
              <th colspan="2" class="text-center">{{ $t('car.petrol_motor') }} / {{ $t('car.diesel_motor') }}:</th>
            </tr>
            <tr>
              <th>{{ $t('car.fuelEngine.cylinderSetup') }}</th>
              <td>{{ car.fuelEngine.cylinderSetup }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.fuelEngine.cylinderVolume') }}</th>
              <td>{{ car.fuelEngine.cylinderVolume }} {{ $t('car.unit.cylinderVolume') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.fuelEngine.maxPower') }} <span v-if="car.type === 'hybrid'">({{
                  $t('car.petrol_motor')
                }})</span></th>
              <td>{{ car.fuelEngine.maxPower }} {{ $t('car.unit.power') }}/{{ kwToHorse(car.fuelEngine.maxPower) }}
                {{ $t('car.unit.power_horse') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.fuelEngine.maxPowerRotation') }}</th>
              <td>{{
                  interval(car.fuelEngine.maxPowerRotationFrom, car.fuelEngine.maxPowerRotationTo)
                }}{{ $t('car.unit.rotation') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.fuelEngine.maxTorque') }}</th>
              <td>{{ car.fuelEngine.maxTorque }} {{ $t('car.unit.torque') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.fuelEngine.maxTorqueRotation') }}</th>
              <td>{{
                  interval(car.fuelEngine.maxTorqueRotationFrom, car.fuelEngine.maxTorqueRotationTo)
                }}{{ $t('car.unit.rotation') }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-center">{{ $t('car.electro_motor') }}:</th>
            </tr>
            <tr>
              <th>{{ $t('car.elEngine.motorType') }}</th>
              <td>{{ car.elEngine.motorType }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.elEngine.maxPower') }} <span v-if="car.type === 'hybrid'">({{
                  $t('car.electro_motor')
                }})</span></th>
              <td>{{ car.elEngine.maxPower }} {{ $t('car.unit.power') }}/{{ kwToHorse(car.elEngine.maxPower) }}
                {{ $t('car.unit.power_horse') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.elEngine.maxTorque') }} <span v-if="car.type === 'hybrid'">({{
                  $t('car.electro_motor')
                }})</span></th>
              <td>{{ car.elEngine.maxTorque }} {{ $t('car.unit.torque') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.elEngine.maxRotation') }} <span v-if="car.type === 'hybrid'">({{
                  $t('car.electro_motor')
                }})</span></th>
              <td>{{ car.elEngine.maxRotation }}{{ $t('car.unit.rotation') }}</td>
            </tr>
            <tr>
              <th colspan="2" class="text-center">{{ $t('car.measurements.header') }}</th>
            </tr>
            <tr>
              <th>{{ $t('car.measurements.length') }}</th>
              <td>{{ car.measurements.length }} {{ $t('car.unit.dimensions') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.measurements.width') }}</th>
              <td>{{ car.measurements.width }} {{ $t('car.unit.dimensions') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.measurements.height') }}</th>
              <td>{{ car.measurements.height }} {{ $t('car.unit.dimensions') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.measurements.wheelBase') }}</th>
              <td>{{ car.measurements.wheelBase }} {{ $t('car.unit.dimensions') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.measurements.curbWeight') }}</th>
              <td>{{ car.measurements.curbWeight }} {{ $t('car.unit.weight') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.measurements.curbWeightEu') }}</th>
              <td>{{ car.measurements.curbWeightEu }} {{ $t('car.unit.weight') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.measurements.capacityWeight') }}</th>
              <td>{{ car.measurements.capacityWeight }} {{ $t('car.unit.weight') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.measurements.totalWeight') }}</th>
              <td>{{ car.measurements.totalWeight }} {{ $t('car.unit.weight') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.measurements.trunkCapacity') }}</th>
              <td>{{ interval(car.measurements.trunkCapacityFrom, car.measurements.trunkCapacityTo, ' / ') }}
                {{ $t('car.unit.volume') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.measurements.trunkDimensions') }}</th>
              <td>{{ car.measurements.trunkLength }} x {{ car.measurements.trunkWidth }} x
                {{ car.measurements.trunkHeight }} {{ $t('car.unit.dimensions') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.measurements.tankVolume') }}</th>
              <td>{{ car.measurements.tankVolume }} {{ $t('car.unit.volume') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.measurements.batteryCapacity') }}</th>
              <td>{{ car.measurements.batteryCapacity }} {{ $t('car.unit.capacity') }}</td>
            </tr>
            <tr>
              <th colspan="2" class="text-center">{{ $t('car.driving.header') }}</th>
            </tr>
            <tr>
              <th>{{ $t('car.driving.maxSpeed') }}</th>
              <td>{{ car.driving.maxSpeed }} {{ $t('car.unit.speed') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.driving.acceleration') }}</th>
              <td>{{ car.driving.acceleration }} {{ $t('car.unit.acceleration') }}</td>
            </tr>
            <tr>
              <th colspan="2" class="text-center">{{ $t('car.consumption.header') }}</th>
            </tr>
            <tr>
              <th colspan="2" class="text-center">{{ $t('car.petrol_motor') }} / {{ $t('car.diesel_motor') }}:</th>
            </tr>
            <tr>
              <th>{{ $t('car.fuelConsumption.extraHigh') }}</th>
              <td>{{ interval(car.fuelConsumption.extraHighFrom, car.fuelConsumption.extraHighTo) }}
                {{ $t('car.unit.fuel_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.fuelConsumption.high') }}</th>
              <td>{{ interval(car.fuelConsumption.highFrom, car.fuelConsumption.highTo) }}
                {{ $t('car.unit.fuel_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.fuelConsumption.medium') }}</th>
              <td>{{ interval(car.fuelConsumption.mediumFrom, car.fuelConsumption.mediumTo) }}
                {{ $t('car.unit.fuel_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.fuelConsumption.low') }}</th>
              <td>{{ interval(car.fuelConsumption.lowFrom, car.fuelConsumption.lowTo) }}
                {{ $t('car.unit.fuel_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.fuelConsumption.combined') }}</th>
              <td>{{ interval(car.fuelConsumption.combinedFrom, car.fuelConsumption.combinedTo) }}
                {{ $t('car.unit.fuel_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.fuelConsumption.testedCity') }}</th>
              <td>{{ interval(car.fuelConsumption.testedCityFrom, car.fuelConsumption.testedCityTo) }}
                {{ $t('car.unit.fuel_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.fuelConsumption.testedMotorway') }}</th>
              <td>{{ interval(car.fuelConsumption.testedMotorwayFrom, car.fuelConsumption.testedMotorwayTo) }}
                {{ $t('car.unit.fuel_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.fuelConsumption.testedCountryRoad') }}</th>
              <td>{{ interval(car.fuelConsumption.testedCountryRoadFrom, car.fuelConsumption.testedCountryRoadTo) }}
                {{ $t('car.unit.fuel_consumption') }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-center">{{ $t('car.electro_motor') }}:</th>
            </tr>
            <tr>
              <th>{{ $t('car.elConsumption.extraHigh') }}</th>
              <td>{{ interval(car.elConsumption.extraHighFrom, car.elConsumption.extraHighTo) }}
                {{ $t('car.unit.el_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.elConsumption.high') }}</th>
              <td>{{ interval(car.elConsumption.highFrom, car.elConsumption.highTo) }} {{
                  $t('car.unit.el_consumption')
                }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.elConsumption.medium') }}</th>
              <td>{{ interval(car.elConsumption.mediumFrom, car.elConsumption.mediumTo) }}
                {{ $t('car.unit.el_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.elConsumption.low') }}</th>
              <td>{{ interval(car.elConsumption.lowFrom, car.elConsumption.lowTo) }} {{
                  $t('car.unit.el_consumption')
                }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.elConsumption.combined') }}</th>
              <td>{{ interval(car.elConsumption.combinedFrom, car.elConsumption.combinedTo) }}
                {{ $t('car.unit.el_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.elConsumption.testedCity') }}</th>
              <td>{{ interval(car.elConsumption.testedCityFrom, car.elConsumption.testedCityTo) }}
                {{ $t('car.unit.el_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.elConsumption.testedMotorway') }}</th>
              <td>{{ interval(car.elConsumption.testedMotorwayFrom, car.elConsumption.testedMotorwayTo) }}
                {{ $t('car.unit.el_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.elConsumption.testedCountryRoad') }}</th>
              <td>{{ interval(car.elConsumption.testedCountryRoadFrom, car.elConsumption.testedCountryRoadTo) }}
                {{ $t('car.unit.el_consumption') }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.elConsumption.range') }}</th>
              <td>{{ interval(car.elConsumption.rangeFrom, car.elConsumption.rangeTo) }} {{ $t('car.unit.range') }}</td>
            </tr>
            <tr>
              <th colspan="2" class="text-center">{{ $t('car.emission.header') }}</th>
            </tr>
            <tr>
              <th>{{ $t('car.emission.value') }}</th>
              <td>{{ interval(car.emission.valueFrom, car.emission.valueTo) }} {{ $t('car.unit.emission') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.emission.standard') }}</th>
              <td>{{ car.emission.standard }}</td>
            </tr>
            <tr>
              <th colspan="2" class="text-center">{{ $t('car.price.header') }}</th>
            </tr>
            <tr>
              <th>{{ $t('car.price.modelPrice') }}</th>
              <td>{{ car.price.modelPrice }} {{ $t('car.unit.price') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.price.versionPrice') }}</th>
              <td>{{ car.price.versionPrice }} {{ $t('car.unit.price') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.price.testedPrice') }}</th>
              <td>{{ car.price.testedPrice }} {{ $t('car.unit.price') }}</td>
            </tr>
            <tr>
              <th colspan="2" class="text-center">{{ $t('car.prosCons.header') }}</th>
            </tr>
            <tr>
              <th>{{ $t('car.prosCons.pros') }}</th>
              <td>
                <ul class="list-unstyled">
                  <li
                    v-for="(value, index) in car.prosCons.pros"
                    :key="`item-${index}`"
                  >
                    <i class="fas fa-plus text-danger"></i>
                    {{ value }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>{{ $t('car.prosCons.cons') }}</th>
              <td>
                <ul class="list-unstyled">
                  <li
                    v-for="(value, index) in car.prosCons.cons"
                    :key="`item-${index}`"
                  >
                    <i class="fas fa-minus text-success"></i>
                    {{ value }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-center">{{ $t('car.rating.header') }}</th>
            </tr>
            <tr>
              <th>{{ $t('car.rating.exterior') }}</th>
              <td>{{ car.rating.exterior }} {{ $t('car.unit.percentage') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.rating.interior') }}</th>
              <td>{{ car.rating.interior }} {{ $t('car.unit.percentage') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.rating.engine') }}</th>
              <td>{{ car.rating.engine }} {{ $t('car.unit.percentage') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.rating.driving') }}</th>
              <td>{{ car.rating.driving }} {{ $t('car.unit.percentage') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.rating.price') }}</th>
              <td>{{ car.rating.price }} {{ $t('car.unit.percentage') }}</td>
            </tr>
            <tr>
              <th>{{ $t('car.rating.total') }}</th>
              <td>{{ car.rating.total }} {{ $t('car.unit.percentage') }}</td>
            </tr>
            </tbody>
          </table>
          <app-detail-system-rows :record="car"></app-detail-system-rows>
        </div>
      </div>
      <div v-else>
        <app-preloader></app-preloader>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import CarTypeMixin from '../../components/mixins/valueObject/CarTypeMixin'
import ModuleView from '../../components/ModuleView'
import Preloader from '../../components/preloader/Preloader'
import ButtonClose from '../../components/shared/ButtonClose'
import ButtonDeleteWithUsages from '../../components/shared/ButtonDeleteWithUsages'
import ButtonEdit from '../../components/shared/ButtonEdit'
import DetailSystemRows from '../../components/shared/DetailSystemRows'
import Media from '../../components/shared/Media'
import CarModel from '../../model/CarModel'
import NotifyService from '../../services/NotifyService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'CarView',
  mixins: [CarTypeMixin],
  data () {
    return {
      car: CarModel,
      error: null,
      dataLoaded: false,
      usages: {},
      usagesDataTableConfig: {
        fields: {
          'field.title': this.$t('article.list.title'),
          'setting.status': this.$t('article.list.status'),
          orderDate: this.$t('article.list.order_date')
        },
        actions: {
          copyToClipboard: 'documentId',
          detail: 'article_detail'
        }
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.ARTICLE_TEST_CAR_PERMISSIONS
    }
  },
  components: {
    appModuleView: ModuleView,
    appButtonDeleteWithUsages: ButtonDeleteWithUsages,
    appButtonEdit: ButtonEdit,
    appButtonClose: ButtonClose,
    appDetailSystemRows: DetailSystemRows,
    appMedia: Media,
    appPreloader: Preloader
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isDeleteButtonVisible () {
      return this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    carImage () {
      return this.$store.getters['car/image']
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    kwToHorse (kw) {
      return parseInt(kw * 1.35962 + '')
    },
    interval (from, to, separator = ' - ') {
      const fromInt = parseInt(from)
      const toInt = parseInt(to)
      let value = 0
      if (fromInt > 0 && toInt > 0) {
        value = from + separator + to
      } else if (fromInt > 0) {
        value = from
      } else if (toInt > 0) {
        value = to
      }
      if (value === 0) {
        return '0'
      } else {
        return value
      }
    },
    getArticleTypes (values) {
      return values.map(function (value) {
        return this.articleTypeValues.find(item => item.id === value)
      }.bind(this))
    },
    getSiteTitle (siteId) {
      const site = this.$store.getters['site/siteById'](siteId)
      if (site) {
        return site.title
      }

      return ''
    },
    getCarTypeByValue (value) {
      const type = this.carTypeValues.find(item => item.id === value)
      if (type) {
        return type.title
      }
      return value
    },
    getCar () {
      this.$store.dispatch('car/fetchOne', this.$route.params.id)
        .then(() => {
          this.dataLoaded = true
          this.car = this.$store.getters['car/detail']
        })
    },
    checkUsages () {
      this.$store.commit('car/setUsagesId', this.car.id)
      this.usages = { page: 1, totalCount: 0, data: [], showLoader: true }
      this.getUsagesList()
    },
    getUsagesList () {
      this.usages.showLoader = true
      this.$store.dispatch('car/fetchUsages')
        .then(() => {
          this.usages.data = this.$store.getters['car/usagesList']
          this.usages.totalCount = this.$store.getters['car/usagesTotalCount']
          this.usages.page = this.$store.getters['car/usagesPage']
          this.usages.showLoader = false
        })
    },
    setUsagesPageAndGetRecords (page) {
      this.$store.commit('car/setUsagesPage', page)
      this.getUsagesList()
    },
    deleteCar () {
      if (this.usages.totalCount > 0) {
        NotifyService.setErrorMessage(this.$t('notify.record_not_deleted'))
        return
      }
      this.$store.dispatch('car/deleteRecord', this.car)
        .then(() => {
          if (this.$store.getters['car/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/articleTest/car')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['car/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  mounted () {
    this.getCar()
  }
}
</script>

<style scoped lang="scss">
  .table-bordered th:first-child {
    width: 200px;
  }
</style>
